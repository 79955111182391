/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RevisionValueDto
 */
export interface RevisionValueDto {
    /**
     * 
     * @type {string}
     * @memberof RevisionValueDto
     */
    fieldName?: string;
    /**
     * 
     * @type {object}
     * @memberof RevisionValueDto
     */
    oldValue?: object;
    /**
     * 
     * @type {object}
     * @memberof RevisionValueDto
     */
    newValue?: object;
}

/**
 * Check if a given object implements the RevisionValueDto interface.
 */
export function instanceOfRevisionValueDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RevisionValueDtoFromJSON(json: any): RevisionValueDto {
    return RevisionValueDtoFromJSONTyped(json, false);
}

export function RevisionValueDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RevisionValueDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fieldName': !exists(json, 'fieldName') ? undefined : json['fieldName'],
        'oldValue': !exists(json, 'oldValue') ? undefined : json['oldValue'],
        'newValue': !exists(json, 'newValue') ? undefined : json['newValue'],
    };
}

export function RevisionValueDtoToJSON(value?: RevisionValueDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fieldName': value.fieldName,
        'oldValue': value.oldValue,
        'newValue': value.newValue,
    };
}

