/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RevisionValueDto } from './RevisionValueDto';
import {
    RevisionValueDtoFromJSON,
    RevisionValueDtoFromJSONTyped,
    RevisionValueDtoToJSON,
} from './RevisionValueDto';

/**
 * 
 * @export
 * @interface RevisionResponseDto
 */
export interface RevisionResponseDto {
    /**
     * 
     * @type {string}
     * @memberof RevisionResponseDto
     */
    revisionType?: string;
    /**
     * 
     * @type {Array<RevisionValueDto>}
     * @memberof RevisionResponseDto
     */
    values?: Array<RevisionValueDto>;
    /**
     * 
     * @type {string}
     * @memberof RevisionResponseDto
     */
    modifiedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof RevisionResponseDto
     */
    modifiedDate?: Date;
}

/**
 * Check if a given object implements the RevisionResponseDto interface.
 */
export function instanceOfRevisionResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RevisionResponseDtoFromJSON(json: any): RevisionResponseDto {
    return RevisionResponseDtoFromJSONTyped(json, false);
}

export function RevisionResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RevisionResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'revisionType': !exists(json, 'revisionType') ? undefined : json['revisionType'],
        'values': !exists(json, 'values') ? undefined : ((json['values'] as Array<any>).map(RevisionValueDtoFromJSON)),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (new Date(json['modifiedDate'])),
    };
}

export function RevisionResponseDtoToJSON(value?: RevisionResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'revisionType': value.revisionType,
        'values': value.values === undefined ? undefined : ((value.values as Array<any>).map(RevisionValueDtoToJSON)),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate.toISOString()),
    };
}

