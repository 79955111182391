/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MvcCategoryResponseDto } from './MvcCategoryResponseDto';
import {
    MvcCategoryResponseDtoFromJSON,
    MvcCategoryResponseDtoFromJSONTyped,
    MvcCategoryResponseDtoToJSON,
} from './MvcCategoryResponseDto';
import type { OwnerDto } from './OwnerDto';
import {
    OwnerDtoFromJSON,
    OwnerDtoFromJSONTyped,
    OwnerDtoToJSON,
} from './OwnerDto';
import type { PlatformMetaResponseDto } from './PlatformMetaResponseDto';
import {
    PlatformMetaResponseDtoFromJSON,
    PlatformMetaResponseDtoFromJSONTyped,
    PlatformMetaResponseDtoToJSON,
} from './PlatformMetaResponseDto';
import type { RevisionResponseDto } from './RevisionResponseDto';
import {
    RevisionResponseDtoFromJSON,
    RevisionResponseDtoFromJSONTyped,
    RevisionResponseDtoToJSON,
} from './RevisionResponseDto';

/**
 * 
 * @export
 * @interface ApplicationResponseDto
 */
export interface ApplicationResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponseDto
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponseDto
     */
    applicationName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponseDto
     */
    description?: string;
    /**
     * 
     * @type {Set<OwnerDto>}
     * @memberof ApplicationResponseDto
     */
    owners?: Set<OwnerDto>;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponseDto
     */
    targetDecommissioningDate?: Date;
    /**
     * 
     * @type {MvcCategoryResponseDto}
     * @memberof ApplicationResponseDto
     */
    criticalityCategory?: MvcCategoryResponseDto;
    /**
     * 
     * @type {Set<string>}
     * @memberof ApplicationResponseDto
     */
    dataClassifications?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponseDto
     */
    status?: ApplicationResponseDtoStatusEnum;
    /**
     * 
     * @type {PlatformMetaResponseDto}
     * @memberof ApplicationResponseDto
     */
    platform?: PlatformMetaResponseDto;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponseDto
     */
    nameVerified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponseDto
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponseDto
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponseDto
     */
    modifiedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponseDto
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {Array<RevisionResponseDto>}
     * @memberof ApplicationResponseDto
     */
    revisions?: Array<RevisionResponseDto>;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponseDto
     */
    canEdit?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponseDto
     */
    rejectedComments?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponseDto
     */
    verified?: boolean;
}


/**
 * @export
 */
export const ApplicationResponseDtoStatusEnum = {
    Active: 'Active',
    Inactive: 'Inactive',
    Rejected: 'Rejected'
} as const;
export type ApplicationResponseDtoStatusEnum = typeof ApplicationResponseDtoStatusEnum[keyof typeof ApplicationResponseDtoStatusEnum];


/**
 * Check if a given object implements the ApplicationResponseDto interface.
 */
export function instanceOfApplicationResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApplicationResponseDtoFromJSON(json: any): ApplicationResponseDto {
    return ApplicationResponseDtoFromJSONTyped(json, false);
}

export function ApplicationResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'applicationName': !exists(json, 'applicationName') ? undefined : json['applicationName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'owners': !exists(json, 'owners') ? undefined : (new Set((json['owners'] as Array<any>).map(OwnerDtoFromJSON))),
        'targetDecommissioningDate': !exists(json, 'targetDecommissioningDate') ? undefined : (new Date(json['targetDecommissioningDate'])),
        'criticalityCategory': !exists(json, 'criticalityCategory') ? undefined : MvcCategoryResponseDtoFromJSON(json['criticalityCategory']),
        'dataClassifications': !exists(json, 'dataClassifications') ? undefined : json['dataClassifications'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'platform': !exists(json, 'platform') ? undefined : PlatformMetaResponseDtoFromJSON(json['platform']),
        'nameVerified': !exists(json, 'nameVerified') ? undefined : json['nameVerified'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (new Date(json['modifiedDate'])),
        'revisions': !exists(json, 'revisions') ? undefined : ((json['revisions'] as Array<any>).map(RevisionResponseDtoFromJSON)),
        'canEdit': !exists(json, 'canEdit') ? undefined : json['canEdit'],
        'rejectedComments': !exists(json, 'rejectedComments') ? undefined : json['rejectedComments'],
        'verified': !exists(json, 'verified') ? undefined : json['verified'],
    };
}

export function ApplicationResponseDtoToJSON(value?: ApplicationResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationId': value.applicationId,
        'applicationName': value.applicationName,
        'description': value.description,
        'owners': value.owners === undefined ? undefined : (Array.from(value.owners as Set<any>).map(OwnerDtoToJSON)),
        'targetDecommissioningDate': value.targetDecommissioningDate === undefined ? undefined : (value.targetDecommissioningDate.toISOString().substring(0,10)),
        'criticalityCategory': MvcCategoryResponseDtoToJSON(value.criticalityCategory),
        'dataClassifications': value.dataClassifications === undefined ? undefined : Array.from(value.dataClassifications as Set<any>),
        'status': value.status,
        'platform': PlatformMetaResponseDtoToJSON(value.platform),
        'nameVerified': value.nameVerified,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate.toISOString()),
        'revisions': value.revisions === undefined ? undefined : ((value.revisions as Array<any>).map(RevisionResponseDtoToJSON)),
        'canEdit': value.canEdit,
        'rejectedComments': value.rejectedComments,
        'verified': value.verified,
    };
}

