/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResponseDto
 */
export interface ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    requestId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    source?: ResponseDtoSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    message?: string;
}


/**
 * @export
 */
export const ResponseDtoSourceEnum = {
    RequestValidation: 'REQUEST_VALIDATION',
    RequestUnmarshalling: 'REQUEST_UNMARSHALLING',
    ResponseMarshalling: 'RESPONSE_MARSHALLING',
    AppOnboard: 'APP_ONBOARD',
    AppUpdate: 'APP_UPDATE',
    AppPlatformUpdate: 'APP_PLATFORM_UPDATE',
    AppDecommission: 'APP_DECOMMISSION',
    FindApp: 'FIND_APP',
    ProductOnboard: 'PRODUCT_ONBOARD',
    ProductUpdate: 'PRODUCT_UPDATE',
    ProductPlatformUpdate: 'PRODUCT_PLATFORM_UPDATE',
    ProductDecommission: 'PRODUCT_DECOMMISSION',
    FindProduct: 'FIND_PRODUCT',
    PlatformOnboard: 'PLATFORM_ONBOARD',
    PlatformUpdate: 'PLATFORM_UPDATE',
    PlatformDecommission: 'PLATFORM_DECOMMISSION',
    FindPlatform: 'FIND_PLATFORM',
    PlatformAppOnboard: 'PLATFORM_APP_ONBOARD',
    PortfolioOnboard: 'PORTFOLIO_ONBOARD',
    PortfolioUpdate: 'PORTFOLIO_UPDATE',
    PortfolioDecommission: 'PORTFOLIO_DECOMMISSION',
    FindAppNameOwner: 'FIND_APP_NAME_OWNER',
    FindPlatformNameOwner: 'FIND_PLATFORM_NAME_OWNER',
    FindPortfolioNameOwner: 'FIND_PORTFOLIO_NAME_OWNER',
    FindPortfolio: 'FIND_PORTFOLIO',
    OwnerConverter: 'OWNER_CONVERTER',
    ErrorDataConverter: 'ERROR_DATA_CONVERTER',
    CompareFields: 'COMPARE_FIELDS',
    Mac: 'MAC',
    Snow: 'SNOW',
    Eac: 'EAC',
    Admiral: 'ADMIRAL',
    AzureUserSearch: 'AZURE_USER_SEARCH',
    SendMail: 'SEND_MAIL',
    SendGrid: 'SEND_GRID',
    Dashboard: 'DASHBOARD',
    AppSearch: 'APP_SEARCH',
    BulkUpdate: 'BULK_UPDATE',
    AppOwnerValidation: 'APP_OWNER_VALIDATION',
    PlatformOwnerValidation: 'PLATFORM_OWNER_VALIDATION',
    PortfolioOwnerValidation: 'PORTFOLIO_OWNER_VALIDATION',
    ApplicationVerification: 'APPLICATION_VERIFICATION',
    FindMvc: 'FIND_MVC',
    AppMvcUpdate: 'APP_MVC_UPDATE',
    AddMvc: 'ADD_MVC',
    DuplicateCheck: 'DUPLICATE_CHECK',
    FindAppEmailEvent: 'FIND_APP_EMAIl_EVENT',
    OwnerRequest: 'OWNER_REQUEST',
    Retina: 'RETINA',
    PlatformChange: 'PLATFORM_CHANGE',
    CallerInfo: 'CALLER_INFO',
    ApplicationEmailEvent: 'APPLICATION_EMAIL_EVENT',
    WorkdayPlatformStructure: 'WORKDAY_PLATFORM_STRUCTURE',
    NotificationSubscription: 'NOTIFICATION_SUBSCRIPTION',
    GetNotification: 'GET_NOTIFICATION',
    PlatformSearch: 'PLATFORM_SEARCH'
} as const;
export type ResponseDtoSourceEnum = typeof ResponseDtoSourceEnum[keyof typeof ResponseDtoSourceEnum];


/**
 * Check if a given object implements the ResponseDto interface.
 */
export function instanceOfResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResponseDtoFromJSON(json: any): ResponseDto {
    return ResponseDtoFromJSONTyped(json, false);
}

export function ResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requestId': !exists(json, 'requestId') ? undefined : json['requestId'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function ResponseDtoToJSON(value?: ResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requestId': value.requestId,
        'source': value.source,
        'message': value.message,
    };
}

