/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OwnerDto } from './OwnerDto';
import {
    OwnerDtoFromJSON,
    OwnerDtoFromJSONTyped,
    OwnerDtoToJSON,
} from './OwnerDto';

/**
 * 
 * @export
 * @interface UpdatePortfolioRequestDto
 */
export interface UpdatePortfolioRequestDto {
    /**
     * 
     * @type {Set<OwnerDto>}
     * @memberof UpdatePortfolioRequestDto
     */
    owners: Set<OwnerDto>;
    /**
     * 
     * @type {string}
     * @memberof UpdatePortfolioRequestDto
     */
    portfolioName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePortfolioRequestDto
     */
    portfolioType: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePortfolioRequestDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePortfolioRequestDto
     */
    status: UpdatePortfolioRequestDtoStatusEnum;
}


/**
 * @export
 */
export const UpdatePortfolioRequestDtoStatusEnum = {
    Active: 'Active',
    Inactive: 'Inactive',
    Rejected: 'Rejected'
} as const;
export type UpdatePortfolioRequestDtoStatusEnum = typeof UpdatePortfolioRequestDtoStatusEnum[keyof typeof UpdatePortfolioRequestDtoStatusEnum];


/**
 * Check if a given object implements the UpdatePortfolioRequestDto interface.
 */
export function instanceOfUpdatePortfolioRequestDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "owners" in value;
    isInstance = isInstance && "portfolioName" in value;
    isInstance = isInstance && "portfolioType" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function UpdatePortfolioRequestDtoFromJSON(json: any): UpdatePortfolioRequestDto {
    return UpdatePortfolioRequestDtoFromJSONTyped(json, false);
}

export function UpdatePortfolioRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePortfolioRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'owners': (new Set((json['owners'] as Array<any>).map(OwnerDtoFromJSON))),
        'portfolioName': json['portfolioName'],
        'portfolioType': json['portfolioType'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'status': json['status'],
    };
}

export function UpdatePortfolioRequestDtoToJSON(value?: UpdatePortfolioRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'owners': (Array.from(value.owners as Set<any>).map(OwnerDtoToJSON)),
        'portfolioName': value.portfolioName,
        'portfolioType': value.portfolioType,
        'description': value.description,
        'status': value.status,
    };
}

