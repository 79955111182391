/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ErrorDataDto } from './ErrorDataDto';
import {
    ErrorDataDtoFromJSON,
    ErrorDataDtoFromJSONTyped,
    ErrorDataDtoToJSON,
} from './ErrorDataDto';

/**
 * 
 * @export
 * @interface DataQualityDto
 */
export interface DataQualityDto {
    /**
     * 
     * @type {string}
     * @memberof DataQualityDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DataQualityDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DataQualityDto
     */
    shortName?: string;
    /**
     * 
     * @type {string}
     * @memberof DataQualityDto
     */
    type?: DataQualityDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DataQualityDto
     */
    status?: DataQualityDtoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof DataQualityDto
     */
    totalRecords?: number;
    /**
     * 
     * @type {number}
     * @memberof DataQualityDto
     */
    invalidRecords?: number;
    /**
     * 
     * @type {number}
     * @memberof DataQualityDto
     */
    validRecords?: number;
    /**
     * 
     * @type {number}
     * @memberof DataQualityDto
     */
    inactiveRecords?: number;
    /**
     * 
     * @type {number}
     * @memberof DataQualityDto
     */
    activeRecords?: number;
    /**
     * 
     * @type {number}
     * @memberof DataQualityDto
     */
    rejectedRecords?: number;
    /**
     * 
     * @type {number}
     * @memberof DataQualityDto
     */
    validScore?: number;
    /**
     * 
     * @type {number}
     * @memberof DataQualityDto
     */
    verifiedRecords?: number;
    /**
     * 
     * @type {number}
     * @memberof DataQualityDto
     */
    unVerifiedRecords?: number;
    /**
     * 
     * @type {number}
     * @memberof DataQualityDto
     */
    missingOwnerRecords?: number;
    /**
     * 
     * @type {number}
     * @memberof DataQualityDto
     */
    invalidOwnerRecords?: number;
    /**
     * 
     * @type {number}
     * @memberof DataQualityDto
     */
    emailCount?: number;
    /**
     * 
     * @type {Date}
     * @memberof DataQualityDto
     */
    emailSentDate?: Date;
    /**
     * 
     * @type {Set<ErrorDataDto>}
     * @memberof DataQualityDto
     */
    errorData?: Set<ErrorDataDto>;
    /**
     * 
     * @type {boolean}
     * @memberof DataQualityDto
     */
    emailSent?: boolean;
}


/**
 * @export
 */
export const DataQualityDtoTypeEnum = {
    Portfolio: 'Portfolio',
    Platform: 'Platform',
    Product: 'Product',
    Application: 'Application'
} as const;
export type DataQualityDtoTypeEnum = typeof DataQualityDtoTypeEnum[keyof typeof DataQualityDtoTypeEnum];

/**
 * @export
 */
export const DataQualityDtoStatusEnum = {
    Active: 'Active',
    Inactive: 'Inactive',
    Rejected: 'Rejected'
} as const;
export type DataQualityDtoStatusEnum = typeof DataQualityDtoStatusEnum[keyof typeof DataQualityDtoStatusEnum];


/**
 * Check if a given object implements the DataQualityDto interface.
 */
export function instanceOfDataQualityDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataQualityDtoFromJSON(json: any): DataQualityDto {
    return DataQualityDtoFromJSONTyped(json, false);
}

export function DataQualityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataQualityDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'totalRecords': !exists(json, 'totalRecords') ? undefined : json['totalRecords'],
        'invalidRecords': !exists(json, 'invalidRecords') ? undefined : json['invalidRecords'],
        'validRecords': !exists(json, 'validRecords') ? undefined : json['validRecords'],
        'inactiveRecords': !exists(json, 'inactiveRecords') ? undefined : json['inactiveRecords'],
        'activeRecords': !exists(json, 'activeRecords') ? undefined : json['activeRecords'],
        'rejectedRecords': !exists(json, 'rejectedRecords') ? undefined : json['rejectedRecords'],
        'validScore': !exists(json, 'validScore') ? undefined : json['validScore'],
        'verifiedRecords': !exists(json, 'verifiedRecords') ? undefined : json['verifiedRecords'],
        'unVerifiedRecords': !exists(json, 'unVerifiedRecords') ? undefined : json['unVerifiedRecords'],
        'missingOwnerRecords': !exists(json, 'missingOwnerRecords') ? undefined : json['missingOwnerRecords'],
        'invalidOwnerRecords': !exists(json, 'invalidOwnerRecords') ? undefined : json['invalidOwnerRecords'],
        'emailCount': !exists(json, 'emailCount') ? undefined : json['emailCount'],
        'emailSentDate': !exists(json, 'emailSentDate') ? undefined : (new Date(json['emailSentDate'])),
        'errorData': !exists(json, 'errorData') ? undefined : (new Set((json['errorData'] as Array<any>).map(ErrorDataDtoFromJSON))),
        'emailSent': !exists(json, 'emailSent') ? undefined : json['emailSent'],
    };
}

export function DataQualityDtoToJSON(value?: DataQualityDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'shortName': value.shortName,
        'type': value.type,
        'status': value.status,
        'totalRecords': value.totalRecords,
        'invalidRecords': value.invalidRecords,
        'validRecords': value.validRecords,
        'inactiveRecords': value.inactiveRecords,
        'activeRecords': value.activeRecords,
        'rejectedRecords': value.rejectedRecords,
        'validScore': value.validScore,
        'verifiedRecords': value.verifiedRecords,
        'unVerifiedRecords': value.unVerifiedRecords,
        'missingOwnerRecords': value.missingOwnerRecords,
        'invalidOwnerRecords': value.invalidOwnerRecords,
        'emailCount': value.emailCount,
        'emailSentDate': value.emailSentDate === undefined ? undefined : (value.emailSentDate.toISOString()),
        'errorData': value.errorData === undefined ? undefined : (Array.from(value.errorData as Set<any>).map(ErrorDataDtoToJSON)),
        'emailSent': value.emailSent,
    };
}

