/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OwnerDto } from './OwnerDto';
import {
    OwnerDtoFromJSON,
    OwnerDtoFromJSONTyped,
    OwnerDtoToJSON,
} from './OwnerDto';

/**
 * 
 * @export
 * @interface UpdatePlatformRequestDto
 */
export interface UpdatePlatformRequestDto {
    /**
     * 
     * @type {Set<OwnerDto>}
     * @memberof UpdatePlatformRequestDto
     */
    owners: Set<OwnerDto>;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlatformRequestDto
     */
    platformName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlatformRequestDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlatformRequestDto
     */
    portfolioId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlatformRequestDto
     */
    platformType?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlatformRequestDto
     */
    status: UpdatePlatformRequestDtoStatusEnum;
}


/**
 * @export
 */
export const UpdatePlatformRequestDtoStatusEnum = {
    Active: 'Active',
    Inactive: 'Inactive',
    Rejected: 'Rejected'
} as const;
export type UpdatePlatformRequestDtoStatusEnum = typeof UpdatePlatformRequestDtoStatusEnum[keyof typeof UpdatePlatformRequestDtoStatusEnum];


/**
 * Check if a given object implements the UpdatePlatformRequestDto interface.
 */
export function instanceOfUpdatePlatformRequestDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "owners" in value;
    isInstance = isInstance && "platformName" in value;
    isInstance = isInstance && "portfolioId" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function UpdatePlatformRequestDtoFromJSON(json: any): UpdatePlatformRequestDto {
    return UpdatePlatformRequestDtoFromJSONTyped(json, false);
}

export function UpdatePlatformRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePlatformRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'owners': (new Set((json['owners'] as Array<any>).map(OwnerDtoFromJSON))),
        'platformName': json['platformName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'portfolioId': json['portfolioId'],
        'platformType': !exists(json, 'platformType') ? undefined : json['platformType'],
        'status': json['status'],
    };
}

export function UpdatePlatformRequestDtoToJSON(value?: UpdatePlatformRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'owners': (Array.from(value.owners as Set<any>).map(OwnerDtoToJSON)),
        'platformName': value.platformName,
        'description': value.description,
        'portfolioId': value.portfolioId,
        'platformType': value.platformType,
        'status': value.status,
    };
}

