/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlatformAppsEmailRequestDto
 */
export interface PlatformAppsEmailRequestDto {
    /**
     * 
     * @type {string}
     * @memberof PlatformAppsEmailRequestDto
     */
    requestType?: PlatformAppsEmailRequestDtoRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PlatformAppsEmailRequestDto
     */
    platformId: string;
    /**
     * 
     * @type {string}
     * @memberof PlatformAppsEmailRequestDto
     */
    status: PlatformAppsEmailRequestDtoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PlatformAppsEmailRequestDto
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof PlatformAppsEmailRequestDto
     */
    verificationDays?: number;
}


/**
 * @export
 */
export const PlatformAppsEmailRequestDtoRequestTypeEnum = {
    AppVerify: 'APP_VERIFY',
    PlatformUpdate: 'PLATFORM_UPDATE',
    OwnerRequest: 'OWNER_REQUEST',
    PlatformChange: 'PLATFORM_CHANGE',
    NotifyPlatforms: 'NOTIFY_PLATFORMS'
} as const;
export type PlatformAppsEmailRequestDtoRequestTypeEnum = typeof PlatformAppsEmailRequestDtoRequestTypeEnum[keyof typeof PlatformAppsEmailRequestDtoRequestTypeEnum];

/**
 * @export
 */
export const PlatformAppsEmailRequestDtoStatusEnum = {
    Active: 'Active',
    Inactive: 'Inactive',
    Rejected: 'Rejected'
} as const;
export type PlatformAppsEmailRequestDtoStatusEnum = typeof PlatformAppsEmailRequestDtoStatusEnum[keyof typeof PlatformAppsEmailRequestDtoStatusEnum];


/**
 * Check if a given object implements the PlatformAppsEmailRequestDto interface.
 */
export function instanceOfPlatformAppsEmailRequestDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "platformId" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function PlatformAppsEmailRequestDtoFromJSON(json: any): PlatformAppsEmailRequestDto {
    return PlatformAppsEmailRequestDtoFromJSONTyped(json, false);
}

export function PlatformAppsEmailRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlatformAppsEmailRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requestType': !exists(json, 'requestType') ? undefined : json['requestType'],
        'platformId': json['platformId'],
        'status': json['status'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'verificationDays': !exists(json, 'verificationDays') ? undefined : json['verificationDays'],
    };
}

export function PlatformAppsEmailRequestDtoToJSON(value?: PlatformAppsEmailRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requestType': value.requestType,
        'platformId': value.platformId,
        'status': value.status,
        'limit': value.limit,
        'verificationDays': value.verificationDays,
    };
}

